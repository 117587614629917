import axios from 'axios';

const API_URL = '/api/urls/';

const createCode = async (formData, token) => {
  const data = {
    destinyUrl: formData.destinyUrl,
    name: formData.name,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, data, config);
  return response.data;
};
const deleteCode = async (codeId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(API_URL + codeId, config);
  return response.data;
};

const getDestinyUrl = async (data) => {
  const response = await axios.get(API_URL + data.id, {
    params: { id: data.id, test: data.test, userLink: data.isUserLink },
  });
  return response.data;
};

const getCodes = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL, config);
  return response.data;
};

const updateCode = async (reqData, token) => {
  const data = {
    destinyUrl: reqData.destinyUrl,
    name: reqData.name,
    status: reqData.status,
    stylingOptions: reqData.stylingOptions,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_URL + reqData.code._id, data, config);
  return response.data;
};

const codesService = {
  createCode,
  deleteCode,
  updateCode,
  getCodes,
  getDestinyUrl,
};

export default codesService;
