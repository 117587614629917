/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch } from 'react-redux';
import { openModal } from '../features/modal/modalSlice';
import QRCodeStyling from 'qr-code-styling';
import { useEffect } from 'react';
import codesService from '../features/codes/codesService';
import { useRef } from 'react';

const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour12: false,
};

function CodeItem({ code }) {
  const dispatch = useDispatch();
  const qrCodeRef = useRef(null);

  useEffect(() => {
    // Update QR code properties here if needed
    if (qrCodeRef.current) {
      qrCodeRef.current.update(code.stylingOptions);
    }
  }, [code.stylingOptions]);
  useEffect(() => {
    if (!qrCodeRef.current) {
      qrCodeRef.current = new QRCodeStyling(code.stylingOptions);
      qrCodeRef.current.append(
        document.getElementById('canvasDashboard' + code._id)
      );
    }
  }, []);

  return (
    <tr className='hover:bg-gray-50'>
      <th className='flex gap-3 px-2 py-4 md:px-6 font-normal text-gray-900'>
        <div className='relative h-10 w-10 items-center'>
          {/*           <img
            className='h-full w-full rounded-full object-cover object-center'
            src={code.qrCode}
            alt=''
          /> */}
          <div
            className='flex h-full rounded-full object-cover object-center'
            id={'canvasDashboard' + code._id}
          ></div>
          {code.status ? (
            <span className='absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white'></span>
          ) : (
            <span className='absolute right-0 bottom-0 h-2 w-2 rounded-full bg-red-400 ring ring-white'></span>
          )}
        </div>
        <div className='text-sm'>
          <div className='font-medium text-gray-700'>{code.name}</div>
          <div className='text-gray-400'>
            {new Date(code.createdAt).toLocaleDateString('es-ES', dateOptions)}
          </div>
        </div>
      </th>
      <td className='px-2 py-4 md:px-6 hidden md:table-cell'>
        {code.status ? (
          <>
            <span className='inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold'>
              <span className='h-1.5 w-1.5 rounded-full bg-green-600  text-green-600'></span>
              <span>Active</span>
            </span>
          </>
        ) : (
          <>
            <span className='inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold'>
              <span className='h-1.5 w-1.5 rounded-full bg-red-600  text-red-600'></span>
              <span>Inactive</span>
            </span>
          </>
        )}
      </td>
      <td className='px-2 py-4 md:px-6 text-xs hidden md:table-cell'>
        {new URL(code.destinyUrl.toString()).hostname.replace('www.', '')}
      </td>
      <td className='px-2 py-4 md:px-6'>
        <div className='flex gap-2 justify-center'>
          <span className='inline-flex justify-center items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600'>
            {code.visits}
          </span>
        </div>
      </td>
      <td className='px-6 py-4'>
        <div className='flex justify-end gap-4 pointer'>
          <a
            x-data="{ tooltip: 'Edit' }"
            href='#'
            onClick={() => dispatch(openModal(code))}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-6 w-6 hover:cursor-pointer'
              x-tooltip='tooltip'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
              />
            </svg>
          </a>
        </div>
      </td>
    </tr>
  );
}

export default CodeItem;
