import React from "react";
//import useEffect and useDispatch from react-redux
import { useEffect } from "react";
//import useSelector from react-redux
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//import toast from react-toastify
import { toast } from "react-toastify";
import StyledInputField from "../components/StyledComponents/StyledInputField";
import StyledInputButton from "../components/StyledComponents/StyledInputButton";
import { useState } from "react";

function MyAccount() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { name, oldPassword, newPassword, confirmPassword } = formData;


  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Password did not match");
    } else {
      const userData = {
        name: name.toLowerCase(),
        oldPassword,
        newPassword,
      };
      console.log(userData);
      //dispatch();
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      setFormData((prevState) => ({
        ...prevState,
        name: user.name,
      }));
    }
  }, [navigate, user]);

  if (user)
    return (
      <div className="container flex flex-col items-center mx-auto mt-10 md:max-w-xl">
        <h1 className="text-xl flex mb-10 text-left">My Account</h1>
        <div className="flex justify-center items-center flex-col mx-auto divide-y-4 space-y-4 w-full md:min-w-xl">
          <div className="flex items-center justify-center mb-4 w-full">
           
          </div>
          <div className="flex flex-col min-w-full md:flex-row w-full">
            <form className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full ">
              <div className="flex flex-col w-full items-center md:items-end">
                <div className="flex flex-col w-full items-center md:items-end">
                  <span className="text-xl font-thin mb-4 w-full">My Info</span>
                  <StyledInputField
                    label="Username"
                    name="name"
                    value={name}
                    className="text-mainText"
                    onChange={onChange}
                  />
                  <span className="text-sm mb-4">
                    Your Link page address will be : http://qrflex.io/
                    <span className="font-bold">{name}</span>
                  </span>

                  <StyledInputField
                    label="Email"
                    name="email"
                    value={user.email}
                    disabled
                  />
                  <StyledInputField
                    label="Codes Left"
                    name="codesLeft"
                    value={user.codesLeft}
                    disabled
                  />
                  <StyledInputField
                    label="Plan Type"
                    name="planType"
                    value={user.planType}
                    disabled
                  />
                </div>
              </div>
              <div className="flex flex-col w-full items-center">
                <span className="text-xl font-thin mb-4">Change Password</span>
                <StyledInputField
                  type="password"
                  label="Old Password"
                  name="oldPassword"
                  onChange={onChange}
                />
                <StyledInputField
                  type="password"
                  label="New Password"
                  name="newPassword"
                  onChange={onChange}
                />
                <StyledInputField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  onChange={onChange}
                />
              </div>
            </form>
          </div>
        </div>
        <StyledInputButton
          onClick={onSubmit}
          className="rounded md:w-full w-1/2 justify-center md:mt-10 "
        >
          Save
        </StyledInputButton>
      </div>
    );
}

export default MyAccount;
