import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CodesForm from '../components/CodesForm';
import Spinner from '../components/Spinner';
import QRTable from '../components/QRTable';
import { getCodes, resetError } from '../features/codes/codesSlice';
import { getMe } from '../features/auth/authSlice';
import Modal from '../components/Modal';
import { toast } from 'react-toastify';
import StyledInputButton from '../components/StyledComponents/StyledInputButton';

function Dashboard() {
  const { isOpen } = useSelector((state) => state.modal);
  const { user } = useSelector((state) => state.auth);
  const { codes, isLoading, isError, message } = useSelector(
    (state) => state.codes
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      navigate('/home');
    } else {
      dispatch(getCodes());
      dispatch(getMe());
      localStorage.setItem('user', JSON.stringify(user));
    }
    if (isError) {
      toast.error(message);
      dispatch(resetError());
    }
  }, [isError, dispatch, navigate, message]);

  if (user)
    return (
      <>
        <div className=' min-w-full min-h-full h-full flex-1 flex flex-col mx-auto md:mt-10 md:flex-row'>
          <section className='w-full flex xl:md:w-1/4'>
            <div className='w-full flex flex-col text-mainText '>
              <CodesForm remainingCodes={user.codesLeft} />
            </div>
          </section>
          <section className='flex flex-col w-full flex-1 h-full md:px-2  '>
            {isLoading ? (
              <Spinner text={message} />
            ) : (
              <>
                <div className='flex flex-row justify-between min-w-full w-full md:rounded-lg  bg-darkBackground text-opositeText p-2 font-thin items-center'>
                  <div className='flex space-x-2 '>
                    <div className='flex'>Remaining Codes:</div>
                    <span className='flex font-bold'>
                      {user.codesLeft}
                    </span>{' '}
                  </div>
                  <div className='flex'>
                    <StyledInputButton className='font-bold'>
                      + Create Advance QR
                    </StyledInputButton>
                  </div>
                </div>
                <div className='flex w-full h-full justify-center flex-1 items-start'>
                  {codes.length > 0 ? (
                    <QRTable codes={codes} />
                  ) : (
                    <div className='flex flex-col glex-grow w-full h-full items-center justify-center space-y-4 flex-1'>
                      <div className='flex font-bold text-xl text-mainText capitalize  '>
                        Hi {user.name}!!
                      </div>
                      <div>You have not added any QR yet</div>
                    </div>
                  )}
                </div>
              </>
            )}
          </section>
          {isOpen && <Modal />}
        </div>
      </>
    );
}

export default Dashboard;
