import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaGlobe,
  FaLinkedin,
} from "react-icons/fa";

const socialIcons = [
  {
    name: "Facebook",
    icon: <FaFacebookF className="text-2xl justify-center" />,
  },
  {
    name: "Twitter",
    icon: <FaTwitter className="text-2xl flex justify-center" />,
  },
  {
    name: "Instagram",
    icon: <FaInstagram className="text-2xl justify-center" />,
  },
  {
    name: "LinkedIn",
    icon: <FaLinkedin className="text-2xl justify-center" />,
  },
  {
    name: "Youtube",
    icon: <FaYoutube className="text-2xl justify-center" />,
  },
  { name: "Web", icon: <FaGlobe className="text-2xl justify-center" /> },
  // Add more social networks as needed
];

export default socialIcons;
