import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserLinks } from '../features/auth/authSlice';
import StyledInputButton from '../components/StyledComponents/StyledInputButton';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import StyledInputField from '../components/StyledComponents/StyledInputField';
import { updateLinks } from '../features/auth/authSlice';
import Spinner from '../components/Spinner';
import Select from 'react-select';
import { FaGripVertical, FaTrash, FaArrowRight } from 'react-icons/fa';
import socialIconList from '../utils/socialIconList';

function MyPage() {
  const { user, isLoading } = useSelector((state) => state.auth);
  const [isChecked, setIsChecked] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
    //localStorage.setItem("userLinks", JSON.stringify(updatedFields));
    updateUserLinks(updatedFields);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    const updatedFields = [...fields];
    const draggedField = updatedFields[draggedIndex];
    updatedFields.splice(draggedIndex, 1);
    updatedFields.splice(index, 0, draggedField);
    setFields(updatedFields);
    setDraggedIndex(null);
    //localStorage.setItem("userLinks", JSON.stringify(updatedFields));
  };

  const handleChange = (index, fieldName, value) => {
    const updatedFields = [...fields];

    updatedFields[index] = {
      ...updatedFields[index],
      [fieldName]: value,
    };

    setFields(updatedFields);
    //localStorage.setItem("userLinks", JSON.stringify(updatedFields));
  };

  const handleAddField = () => {
    const updatedFields = [...fields, { name: '', url: '', icon: '' }];
    setFields(updatedFields);
    localStorage.setItem('userLinks', JSON.stringify(updatedFields));
  };

  const updateUserLinks = (updateFields) => {
    const nonEmptyFields = updateFields.filter(
      (field) => field.name !== '' || field.url !== ''
    );

    const updatedFields = nonEmptyFields.map((field) => {
      if (!field.icon) {
        return { ...field, icon: 'Web' };
      }
      return field;
    });

    const formData = updatedFields.map(({ name, url, icon }) => ({
      name,
      url,
      icon,
    }));
    dispatch(updateLinks(formData)).then(() => {
      dispatch(getUserLinks(user.name));
    });
  };

  const iconOptions = socialIconList.map((icon) => ({
    value: icon.name,
    label: <div>{icon.icon}</div>,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '50px',
      border: 'none',
      boxShadow: 'none',
      padding: '0',
      margin: '0',
      minWidth: '50px',
      minHeight: '50px',
      display: 'flex',
      alignItems: 'center',
      allignContent: 'center',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
    }),
  };

  useEffect(() => {
    dispatch(getUserLinks(user.name));

    // const storedUserLinks = JSON.parse(localStorage.getItem("userLinks"));
    // if (storedUserLinks) {
    //   setFields(storedUserLinks);
    // }
  }, []);

  useEffect(() => {
    setFields(user.links);
  }, [user.links]);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className='flex flex-row min-h-screen w-full flex-grow bg-mainText text-mainBackground justify-center'>
        <div className='flex mx-auto flex-col flex-grow w-full max-w-4xl h-full'>
          <div className='flex text-2xl font-thin justify-center'>Links</div>
          <div className='flex flex-row w-full justify-center mt-5'>
            <Link
              to={'/' + user.name}
              target='_blank'
              rel='noopener noreferrer'
            >
              http://qrflex.io/<span className='font-bold'>{user.name}</span>
            </Link>
          </div>
          <div className='flex flex-row w-full justify-center mt-5 items-center'></div>

          <div className='flex mt-10 flex-col w-full mx-auto'>
            <div className='flex flex-row space-x-4 mt-10 w-full items-center justify-end px-4'>
              {(!fields || fields.length < 1) && (
                <>
                  <span className='flex flex-row font-thin font-xl items-center '>
                    You can start adding your pages here
                  </span>{' '}
                  <FaArrowRight />
                </>
              )}
              <StyledInputButton
                type='button'
                className='rounded-full px-2 shadow-md w-fit justify-center'
                onClick={handleAddField}
              >
                +
              </StyledInputButton>
            </div>
            <div className='flex flex-col w-full divide-y-2 space-y-4 divide-gray-500 items-center p-4'>
              {fields &&
                fields.map((field, index) => (
                  <div
                    className='flex flex-row w-full items-center space-x-4 p-4 bg-mainBackground border-2 text-mainText rounded-full shadow-md shadow-white justify-center text-center '
                    key={index}
                  >
                    <div
                      className='drag-icon items-center w-1/12 justify-center flex h-full cursor-pointer'
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragOver={handleDragOver}
                      onDrop={() => handleDrop(index)}
                    >
                      <FaGripVertical className='text-xl justify-center' />
                    </div>
                    <button
                      type='button'
                      className='w-1/12'
                      onClick={() => handleDeleteField(index)}
                    >
                      <FaTrash />
                    </button>
                    <div className='flex flex-row items-center w-full md:flex-row md:space-x-4'>
                      <div className='flex flex-col w-full mr-2 '>
                        <StyledInputField
                          label='Name'
                          value={field.name}
                          name='name'
                          onChange={(e) =>
                            handleChange(index, 'name', e.target.value)
                          }
                          placeholder='Name'
                        ></StyledInputField>
                        <StyledInputField
                          label='URL'
                          name='url'
                          value={field.url}
                          onChange={(e) =>
                            handleChange(index, 'url', e.target.value)
                          }
                          placeholder='Link'
                        ></StyledInputField>
                      </div>
                      <div className='flex flex-col justify-center items-center text-neutral-900'>
                        <Select
                          name='icon'
                          value={iconOptions.find(
                            (option) => option.value === field.icon
                          )}
                          options={iconOptions}
                          onChange={(selectedOption) =>
                            handleChange(index, 'icon', selectedOption.value)
                          }
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className='flex flex-row space-x-4 mt-10 w-full items-center justify-center'>
            {fields && fields.length >= 1 && (
              <>
                <StyledInputButton
                  type='submit'
                  className='rounded-full px-2 shadow-md w-fit justify-center'
                  onClick={() => updateUserLinks(fields)}
                >
                  Save
                </StyledInputButton>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPage;
