import { FaUser, FaBars } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StyledInputButton from "./StyledComponents/StyledInputButton";
import { openSideMenu } from "../features/sidemenu/smSlice";
import SideMenu from "./SideMenu";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  return (
    <header className="flex container min-w-full bg-darkBackground w-screen justify-center mx-auto text-opositeText shadow-xl md:max-w-3xl ">
      <nav className="relative container mx-auto p-6 min-w-full">
        <div className="flex items-center justify-between">
          <div className="flex pt-2 items-center flex-row justify-center">
            <div className="justify-center flex text-2xl">
              <Link to="/">
                QrFlex<span className="text-primaryButton">.io</span>
              </Link>
            </div>
          </div>
          <div className="flex space-x-6 items-center">
            <div className="flex divide-x-2 divide-dotted divide-x-reverse">
              {user ? (
                <>
                  <StyledInputButton
                    onClick={() => {
                      navigate("/upgradePlan");
                    }}
                  >
                    Upgrade Plan
                  </StyledInputButton>
                  <span
                    onClick={() => navigate("/dashboard")}
                    className="hidden md:flex flex-row items-center  px-2 hover:text-gray-600 cursor-pointer"
                  >
                    Dashboard
                  </span>
                  <span
                    onClick={() => navigate("/mypage")}
                    className="hidden md:flex flex-row items-center px-2 hover:text-gray-600 cursor-pointer"
                  >
                    Edit Page
                  </span>
                </>
              ) : (
                <div className="hidden space-x-6  md:flex ">
                  <span
                    onClick={() => navigate("/aboutus")}
                    className="flex flex-row items-center space-x-4  hover:text-gray-600 cursor-pointer"
                  >
                    About Us
                  </span>
                </div>
              )}
            </div>

            <div className="flex">
              <ul className="flex space-x-6 ">
                {user ? (
                  <>
                    <div>
                      <div className="flex flex-row">
                        <span
                          onClick={() => navigate("/myaccount")}
                          className="hidden md:flex flex-row items-center space-x-2 hover:text-gray-600 cursor-pointer capitalize
                        "
                        >
                          <FaUser /> <span>Hi {user.name}!</span>
                        </span>

                        <div>
                          <span className="hidden">{user.email}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <li className="hover:text-gray-600 flex">
                      <Link to="/login">Login</Link>
                    </li>
                    <li className="hover:text-gray-600 flex">
                      <Link to="/register">Sign Up</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {user && (
              <div className="flex space-x-6 items-center">
                <FaBars
                  className=" text-opositeText font-bold text-xl px-2 rounded py-1 h-10 w-10 cursor-pointer"
                  onClick={() => dispatch(openSideMenu())}
                ></FaBars>
              </div>
            )}
          </div>
        </div>
      </nav>
      <SideMenu></SideMenu>
    </header>
  );
}

export default Header;
