export default function StyledInputField(props) {
  return (
    <>
      <div className="flex h-10 w-full flex-row-reverse overflow-clip rounded-lg mb-2">
        <input
          className={
            " peer w-full rounded-r-lg border border-slate-400 px-2 text-slate-900 placeholder-slate-400 transition-colors duration-300 focus:border-sky-400 focus:outline-none " +
            props.className
          }
          type={props.type}
          id={props.id}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <label
          className="flex w-[7rem] items-center rounded-l-lg border border-slate-400 bg-slate-50 px-2 text-sm text-slate-600 transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-mainText"
          htmlFor={props.name}
        >
          {props.label ? props.label : props.name}
        </label>
      </div>
    </>
  );
}
