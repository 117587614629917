import React, { useState } from 'react';
import StyledInputField from './StyledComponents/StyledInputField'; // Adjust the import path as necessary
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { deleteCode, updateCode } from '../features/codes/codesSlice';
import { closeModal } from '../features/modal/modalSlice';
import QrEditStyleForm from './QrEditStyleForm';

import { useEffect } from 'react';

const QrDataForm = ({ code, styleOptions, setStyleOptions, isChecked }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    destinyUrl: code.destinyUrl,
    name: code.name,
  });
  const [activeTab, setActiveTab] = useState('data');

  function reverseArray(array) {
    let newArray = [...array];
    return newArray.reverse();
  }
  useEffect(() => {
    console.log(activeTab);
  }, [activeTab]);

  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let newUrl = formData.destinyUrl;

    if (!formData.destinyUrl.includes('://')) {
      newUrl = 'http://' + formData.destinyUrl;
    }

    const data = {
      code,
      destinyUrl: newUrl,
      name: formData.name,
      status: isChecked,
      stylingOptions: styleOptions,
    };
    if (
      code.name !== formData.name ||
      code.destinyUrl !== newUrl ||
      code.status !== isChecked ||
      code.stylingOptions !== styleOptions
    )
      dispatch(updateCode(data));

    dispatch(closeModal());
  };

  return (
    <div className='flex flex-col container mt-5 mb-5 w-full'>
      <div className='tab-bar flex flex-row sm:flex-row justify-center w-full'>
        <button
          onClick={() => setActiveTab('data')}
          className={`flex items-center justify-center px-4 py-2 text-gray-600 font-medium rounded-t-md hover:bg-gray-100 border-b-2 focus:outline-none ${
            activeTab === 'data' ? 'border-blue-500' : ''
          }`}
        >
          QR Data
        </button>
        <button
          onClick={() => setActiveTab('style')}
          className={`
    flex items-center justify-center px-4 py-2
    text-gray-600 font-medium rounded-t-md
    hover:bg-gray-100 border-b-2 focus:outline-none
    ${activeTab === 'style' ? 'border-blue-500' : 'border-transparent'}
  `}
        >
          QR Style
        </button>
      </div>
      <form onSubmit={onSubmit} className='flex flex-col items-center w-full'>
        {activeTab === 'data' && (
          <div
            id='qrData'
            className='flex flex-col items-center justify-center w-full '
          >
            <StyledInputField
              type='text'
              name='name'
              id='name'
              label='Name'
              value={formData.name}
              onChange={onChange}
            />
            <StyledInputField
              name='destinyUrl'
              id='destinyUrl'
              label='URL:'
              type='text'
              value={formData.destinyUrl}
              onChange={onChange}
            />
            <StyledInputField
              type='text'
              name='code_createdAt'
              id='code_createdAt'
              label='Created At:'
              disabled
              value={new Date(code.createdAt).toLocaleDateString(
                'es-ES',
                dateOptions
              )}
            />
            <StyledInputField
              type='text'
              name='code_updatedAt'
              id='code_updatedAt'
              label='Updated At:'
              disabled
              value={new Date(code.updatedAt).toLocaleDateString(
                'es-ES',
                dateOptions
              )}
            />

            <div className='flex flex-col container mt-5 mb-5'>
              {code.history.length !== 0 && (
                <div className='font-bold mb-5'>History: </div>
              )}
              <ul className='divide-y-2 h-24 overflow-y-auto scrollbar-hide '>
                {reverseArray(code.history).map((historyEntry) => (
                  <li key={historyEntry.date}>
                    <span className=' font-bold text-xs'>
                      {' '}
                      {new Date(historyEntry.date).toLocaleDateString(
                        'es-ES',
                        dateOptions
                      )}
                    </span>{' '}
                    :
                    <span className='font-thin'>
                      {' '}
                      {historyEntry.oldUrl.toString().replace('www.', '')}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className='flex flex-col items-center justify-center w-full'>
          {activeTab === 'style' && (
            <div id='QrStyle' className='flex flex-col container mt-5 mb-5'>
              <QrEditStyleForm
                styleOptions={styleOptions}
                setStyleOptions={setStyleOptions}
              />
            </div>
          )}
          <div className='flex mx-auto container text-white'>
            <div className='flex flex-row items-center mx-auto justify-between container '>
              <button
                type='button'
                className='bg-red-500 flex items-center mt-5 text-center justify-center hover:bg-red-400 text-opositeText font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-blue-500 rounded'
                onClick={() => {
                  dispatch(deleteCode(code._id));
                  dispatch(closeModal());
                }}
              >
                <FaTrashAlt /> Delete
              </button>
              <button
                type='button'
                className='bg-gray-500 flex items-center mt-5 text-center justify-center hover:bg-gray-400 text-opositeText font-bold py-2 px-4 border-b-4 border-gray-700 hover:border-blue-500 rounded'
                onClick={() => {
                  dispatch(closeModal());
                }}
              >
                Cancel
              </button>
              <button
                type='submit'
                id='submit'
                className='bg-green-500 flex  items-center mt-5 text-center justify-center hover:bg-gray-400 text-opositeText font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QrDataForm;
