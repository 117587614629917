import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import codesService from '../codes/codesService';
import { authSlice } from '../auth/authSlice';

const initialState = {
  codes: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  destinyUrl: '',
  status: '',
};

export const createCode = createAsyncThunk(
  'codes/create',
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const response = await codesService.createCode(formData, token);

      if (response) {
        const codesLeft = await thunkAPI.getState().auth.user.codesLeft;
        await thunkAPI.dispatch(authSlice.actions.updateUser(codesLeft - 1));
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateCode = createAsyncThunk(
  'codes/updateCode',
  async (data, thunkAPI) => {
    try {
      const token = await thunkAPI.getState().auth.user.token;
      return await codesService.updateCode(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCodes = createAsyncThunk(
  'codes/getCodes',
  async (_, thunkAPI) => {
    try {
      const token = await thunkAPI.getState().auth.user.token;
      return await codesService.getCodes(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDestinyUrl = createAsyncThunk(
  'codes/getDestinyUrl',
  async (data, thunkAPI) => {
    try {
      return await codesService.getDestinyUrl(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCode = createAsyncThunk(
  'codes/deleteCode',
  async (codeId, thunkAPI) => {
    try {
      const token = await thunkAPI.getState().auth.user.token;
      const response = await codesService.deleteCode(codeId, token);
      if (response) {
        const codesLeft = (await thunkAPI.getState().auth.user.codesLeft) + 1;
        await thunkAPI.dispatch(authSlice.actions.updateUser(codesLeft));
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const codeSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetError: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.codes.push(action.payload);
      })
      .addCase(createCode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCodes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCodes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.codes = action.payload;
      })
      .addCase(getCodes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.codes = state.codes.filter(
          (code) => code._id !== action.payload._id
        );
      })
      .addCase(deleteCode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDestinyUrl.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDestinyUrl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.destinyUrl = action.payload.destinyUrl;
        state.status = action.payload.status;
      })
      .addCase(getDestinyUrl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const updatedCode = action.payload;
        const index = state.codes.findIndex(
          (code) => code._id === updatedCode._id
        );
        if (index !== -1) {
          state.codes[index] = updatedCode;
        }
      })
      .addCase(updateCode.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(updateCode.pending, (state) => {
        state.isLoading = true;
        state.isUpdated = false;
      });
  },
});

export const { reset, resetError } = codeSlice.actions;
export default codeSlice.reducer;
