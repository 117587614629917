import React from "react";
import CodeItem from "../components/CodeItem";

function QRTable(props) {
  return (
    <div className="flex overflow-hidden min-w-full items-center rounded-lg border border-gray-200 shadow-md m-5">
      <table className="w-full min-w-full border-collapse bg-white text-left text-sm text-gray-500 container">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-2 py-4 md:px-6 font-medium text-gray-900"
            >
              QR
            </th>
            <th
              scope="col"
              className="px-2 py-4 md:px-6 font-medium text-gray-900 hidden md:table-cell"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-2 py-4 md:px-6 font-medium text-gray-900 hidden md:table-cell"
            >
              Url
            </th>
            <th
              scope="col"
              className="px-2 py-4 md:px-6 font-medium text-gray-900"
            >
              Views
            </th>
            <th
              scope="col"
              className="px-6 py-4 font-medium text-gray-900"
            ></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
          {[...props.codes].reverse().map((code) => (
            <CodeItem key={code._id} code={code} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default QRTable;
