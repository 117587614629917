import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaArrowDown } from 'react-icons/fa';

import { useEffect } from 'react';
import StyledInputButton from '../components/StyledComponents/StyledInputButton';

function Home() {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  function goToRegister() {
    navigate('/register');
  }
  // useEffect(() => {
  //   if (user) {
  //     navigate("/dashboard");
  //   }
  // }, [navigate, user]);

  return (
    <>
      <div className='flex min-h-full h-full flex-col text-mainBackground bg-darkBackground w-full mx-auto  '>
        <div className='sticky min-h-screen max-w-3xl top-0 h-full flex-grow flex flex-col items-center justify-between mx-auto '>
          <section
            id='home'
            className='flex min-w-full flex-grow flex-col items-center justify-center h-full mx-auto'
          >
            {/* Flex Container */}
            <div className='flex flex-col md:flex-row justify-center items-center px-6 mx-auto mt-10'>
              {/* Left Item */}
              <div className='flex flex-col space-y-6 items-end'>
                <div className='flex flex-col items-end justify-center mb-10'>
                  <h1 className=' text-4xl font-thin text-center md:text-5xl md:text-left'>
                    <span className='text-6xl font-bold'>QrFlex</span>
                    <span className='text-primaryButton font-bold'>
                      .io
                    </span>{' '}
                    lets you host and manage your QRs and Social Media Links.
                  </h1>
                  <div className='flex flex-col w-full items-end md:items-center justify-center text-4xl space-y-5 text-primaryButton font-bold'>
                    All in one place.
                  </div>
                </div>
                <div className='flex flex-col items-end justify-center space-y-5 mb-10'>
                  <p
                    className=' text-center
                 justify-center items-center flex flex-col space-y-4'
                  >
                    <span className='font-thin'>
                      Effortlessly create, update, and customize your codes,
                      while having the flexibility to change their destinations
                      at any time.
                    </span>
                  </p>
                </div>
              </div>
              {/* Right Item */}
              <div className='flex flex-col items-center justify-center h-full md:min-w-max mt-10'>
                <div className='flex marker:justify-center p-4 '>
                  <StyledInputButton
                    type='Submit'
                    id='submit'
                    onClick={goToRegister}
                    className='rounded-full font-bold h-15 w-40'
                  >
                    Get Started
                  </StyledInputButton>
                </div>
                <div className='flex-row marker:justify-center p-4 text-center space-y-5 mt-10'>
                  <div className='text-center'>Already a user ?</div>
                  <a
                    href='/login'
                    className='text-orange-500 hover:text-gray-600'
                  >
                    Log in here!
                  </a>
                </div>
              </div>
            </div>
          </section>
          <div className='flex flex-col items-center justify-end font-thin italic '>
            <span className='flex'> Why use QrFlex.io ???</span>
            <FaArrowDown className='mt-20 mb-10'></FaArrowDown>
          </div>
        </div>
        <div className='px-6  sticky top-0 h-screen flex flex-col md:flex-row bg-lightOrange text-mainText '>
          <div className='flex flex-col h-full w-full'>
            <p className='flex text-4xl font-thin w-full justify-center items-center mt-10'>
              Dynamic QR Codes
            </p>
            <div className='flex items-start flex-col md:flex-row h-full w-full'>
              <div className='flex flex-col items-center justify-center md:mt-5 w-full'>
                <div className='flex w-full items-center justify-center'>
                  <img src='img_page2.png' alt='logo' />
                </div>
              </div>
              <div className='flex flex-col items-start justify-start mt-5 m md:items-center md:justify-center h-full md:mt-0 w-full'>
                <div className='flex flex-col items-start justify-start md:justify-center md:mt-5 w-full'>
                  <p className='flex flex-col text-xl font-thin w-full justify-center items-center '>
                    <span className='flex font-semibold mb-5'>
                      Elevate your connectivity with Dynamic QR codes that adapt
                      to your needs!{' '}
                    </span>
                    <span>
                      Our platform allows you to create and customize QR codes
                      that can be effortlessly updated with the destination of
                      your choice. Stay in control of your content and engage
                      your audience like never before.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='px-6 sticky  min-h-screen top-0  w-full flex flex-col md:flex-row-reverse md:justify-between bg-blueishGray text-mainBackground'>
          <div className='flex items-center justify-center w-full'>
            <img
              className='flex justify-center items-center rounded-xl'
              src='phonebig.png'
              alt='logo'
            />
          </div>
          <div className='flex flex-col items-start justify-start w-full h-full md:items-end md:justify-center md:text-end'>
            <div className='text-4xl font-thin justify-center'>
              <span className='font-semibold'>All</span>{' '}
              <span className='font-thin'>
                your social media links in one place.
              </span>
            </div>

            <div className='flex items-start justify-start font-thin md:justify-end md:items-end'>
              Create a QR code to your links that won't change even if your
              change your username
            </div>
          </div>
        </div>
        <div className='px-6 sticky top-0 min-h-screen w-full flex flex-col md:flex-row-reverse md:justify-between bg-blackBlueish text-mainBackground'>
          <div className='flex items-center flex-col md:flex-row-reverse justify-center w-full h-full'>
            <div className='flex items-center justify-center w-full '>
              <img
                className='flex justify-center items-center w-full'
                src='img_api2.jpg'
                alt='logo'
              />
            </div>
            <div className='flex flex-col items-start justify-start mt-20 w-full h-full md:items-end md:justify-center md:text-end md:mr-20'>
              <div className='text-4xl font-thin justify-start'>
                {' '}
                <span className='font-thin'>
                  Connect your systems with our{' '}
                  <span className='font-semibold'>API</span>.
                </span>
              </div>

              <div className='flex items-start justify-start font-thin md:justify-end md:items-end'>
                Use our API to change how your QR code behaves
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
