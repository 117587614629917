import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getDestinyUrl } from '../features/codes/codesSlice';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function Redirect() {
  const { destinyUrl, status } = useSelector((state) => state.codes);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isLoading, isSuccess, message } = useSelector((state) => state.codes);

  useEffect(() => {
    const data = {
      id: searchParams.get('id'),
      test: searchParams.get('test'),
      isUserLink: searchParams.get('userLink'),
    };
    console.log(data);
    if (!data.id) {
      navigate('/home');
    }
    const fetchData = async () => {
      const response = await dispatch(getDestinyUrl(data));
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isSuccess && status) {
      window.location.replace(destinyUrl);
    }
  }, [
    destinyUrl,
    isLoading,
    dispatch,
    message,
    isSuccess,
    searchParams,
    status,
  ]);

  return (
    <div className='flex w-full min-h-full h-screen justify-center items-center bg-mainText'>
      {status === true ? (
        <div className='flex flex-col text-opositeText items-center text-2xl space-y-4 divide-y-2'>
          <div className='flex flex-col'>
            <span className='flex text-sm font-thin'>Powered by</span>
            <div className='flex flex-row text-2xl font-thin justify-center'>
              <span className=' font-bold'> QrFlex</span>
              <span className='text-primaryButton font-bold'>.io</span>
            </div>
          </div>
        </div>
      ) : (
        !isLoading && (
          <div className='flex flex-col text-opositeText items-center text-xl font-thin space-y-4 divide-y-2'>
            This code is deactivated by the creator
          </div>
        )
      )}
    </div>
  );
}

export default Redirect;
