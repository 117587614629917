// eslint-disable-next-line no-unused-vars
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Header from './components/Header';
import Redirect from './pages/Redirect';
import Home from './pages/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpgradePlan from './pages/UpgradePlan';
import About from './pages/AboutUs';
import MyAccount from './pages/MyAccount';
import Landing from './pages/Landing';
import MyPage from './pages/MyPage';

function App() {
  const location = useLocation();
  const hideOn = ['/home', '/', '/redirect']; // add paths here where you want to hide the header

  return (
    <>
      <div className='bg-mainBackground min-h-screen text-mainText flex flex-col '>
        {hideOn.includes(location.pathname) ? null : <Header />}
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/:username' element={<Landing />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/register' element={<Register />}></Route>
          <Route path='/redirect' element={<Redirect />}></Route>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/upgradePlan' element={<UpgradePlan />}></Route>
          <Route path='/myaccount' element={<MyAccount />}></Route>
          <Route path='/aboutus' element={<About />}></Route>
          <Route path='/mypage' element={<MyPage />}></Route>
        </Routes>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
