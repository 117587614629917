import { useState, useEffect } from "react";
import { FaQrcode } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";

function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
  });

  const { name, email, password, password2 } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate("/dashboard");
    }

    dispatch(reset);
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      toast.error("Password did not match");
    } else {
      const userData = {
        name: name.toLowerCase(),
        email,
        password,
      };

      dispatch(register(userData));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section id="registerContainer">
        <div className="flex container flex-col justify-center items-center px-6 m-auto mt-10 mb-10 space-y-5">
          <FaQrcode className="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left" />
          <h1 className="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
            Register Page
          </h1>
        </div>
        <div className="flex container flex-col md:flex-row justify-center items-center px-6 m-auto mt-10 mb-10">
          <form className="space-y-5" onSubmit={onSubmit}>
            <div className="flex h-10 w-full flex-row-reverse overflow-clip rounded-lg">
              <input
                className="peer flex w-full rounded-r-lg border border-slate-400 px-2 text-slate-900 placeholder-slate-400 transition-colors duration-300 focus:border-sky-400 focus:outline-none"
                id="name"
                name="name"
                value={name}
                placeholder="Enter your name"
                onChange={onChange}
              />
              <label
                className="flex items-center w-[7rem] rounded-l-lg border border-slate-400 bg-slate-50 px-2 text-sm text-slate-400 transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-mainText"
                htmlFor="name"
              >
                Username
              </label>
            </div>
            <div className="flex h-10 w-full flex-row-reverse overflow-clip rounded-lg">
              <input
                className="peer flex w-full rounded-r-lg border border-slate-400 px-2 text-slate-900 placeholder-slate-400 transition-colors duration-300 focus:border-sky-400 focus:outline-none"
                id="email"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={onChange}
              />
              <label
                className="flex items-center w-[7rem] rounded-l-lg border border-slate-400 bg-slate-50 px-2 text-sm text-slate-400 transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-mainText"
                htmlFor="email"
              >
                Email
              </label>
            </div>
            <div className="flex h-10 w-full flex-row-reverse overflow-clip rounded-lg">
              <input
                type="password"
                className="peer flex w-full rounded-r-lg border border-slate-400 px-2 text-slate-900 placeholder-slate-400 transition-colors duration-300 focus:border-sky-400 focus:outline-none"
                id="password"
                name="password"
                value={password}
                placeholder="Enter your Password"
                onChange={onChange}
              />
              <label
                className="flex items-center w-[7rem] rounded-l-lg border border-slate-400 bg-slate-50 px-2 text-sm text-slate-400 transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-mainText"
                htmlFor="password"
              >
                Password
              </label>
            </div>
            <div className="flex h-10 w-full flex-row-reverse overflow-clip rounded-lg">
              <input
                type="password"
                className="peer flex w-full rounded-r-lg border border-slate-400 px-2 text-slate-900 placeholder-slate-400 transition-colors duration-300 focus:border-sky-400 focus:outline-none"
                id="password2"
                name="password2"
                value={password2}
                placeholder="Confirm your password"
                onChange={onChange}
              />
              <label
                className="flex items-center w-[7rem] rounded-l-lg border border-slate-400 bg-slate-50 px-2 text-sm text-slate-400 transition-colors duration-300 peer-focus:border-sky-400 peer-focus:bg-sky-400 peer-focus:text-mainText"
                htmlFor="password2"
              >
                {" "}
                Confirm Password
              </label>
            </div>

            <button
              type="Submit"
              className="bg-orange-500 flex min-w-full items-center mt-5 text-center justify-center hover:bg-orange-400 text-mainBackground font-bold py-3 px-6 border-b-4 border-orange-700 hover:border-orange-500 rounded-full"
              id="submit"
            >
              Create Account
            </button>
          </form>
          <div className="flex-row marker:justify-center p-4 text-center space-y-5">
            <div className="text-center">Already a user ?</div>
            <a href="/login" className="text-orange-500 hover:text-gray-600">
              Log in here!
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Register;
