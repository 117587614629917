import { useState } from "react";
import { useDispatch } from "react-redux";
import { createCode } from "../features/codes/codesSlice";
import StyledInputField from "./StyledComponents/StyledInputField";
import StyledInputButton from "./StyledComponents/StyledInputButton";
import { useSelector } from "react-redux";

function CodesForm(props) {
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    destinyUrl: "",
    name: "",
  });
  const [isValid, setValid] = useState(true);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    let newUrl = formData.destinyUrl;
    if (!formData.destinyUrl.includes("://")) {
      newUrl = "http://" + formData.destinyUrl;
    }
    if (isWebpageURL(newUrl)) {
      const newName = new URL(newUrl).hostname;
      const data = {
        destinyUrl: formData.destinyUrl,
        name: newName,
      };

      dispatch(createCode(data));
      setFormData({ destinyUrl: "", name: "" });
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  function isWebpageURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const error = (
    <span className=" flex text-red-500 ">Please add a valid url</span>
  );

  return (
    <section
      className={
        "flex flex-col text-mainText bg-darkBackground min-w-full w-full justify-center items-center   px-2 shadow-2xl " +
        props.className
      }
    >
      <div className="flex mt-5 mb-5 justify-center  md:justify-start font-thin text-opositeText">
        Quick QR:
      </div>
      <form onSubmit={onSubmit} className="flex flex-row mx-auto ">
        {/* <StyledInputField
          type="text"
          name="name"
          id="name"
          label="Name"
          value={formData.name}
          onChange={onChange}
        /> */}

        <StyledInputField
          type="text"
          name="destinyUrl"
          id="destinyUrl"
          label="http://"
          value={formData.destinyUrl}
          onChange={onChange}
          disabled={user.codesLeft === 0 ? true : false}
        />

        <div className="flex h-10 mx-auto">
          <StyledInputButton
            type="submit"
            className="rounded-full px-2 shadow-md"
            disabled={user.codesLeft === 0 ? true : false}
          >
            +
          </StyledInputButton>
        </div>
      </form>
      <div className="flex flex-col mt-5 items-center">{!isValid && error}</div>
    </section>
  );
}

export default CodesForm;
