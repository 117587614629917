export default function StyledInputButton(props) {
  return (
    <button
      onClick={props.onClick}
      className={
        "bg-primaryButton ml-2 flex px-4 py-2 items-center justify-center hover:bg-orange-400 text-opositeText  border-b-2 border-orange-700 hover:border-orange-500 disabled:bg-gray-400 disabled:border-gray-700 " +
        props.className
      }
      type={props.type}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
