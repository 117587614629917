import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import codeReducer from "..//features/codes/codesSlice";
import modalReducer from "../features/modal/modalSlice";
import sideMenuReducer from "../features/sidemenu/smSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    codes: codeReducer,
    modal: modalReducer,
    sidemenu: sideMenuReducer,
  },
});
