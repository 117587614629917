import { useDispatch } from 'react-redux';
import { closeModal } from '../features/modal/modalSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { updateCode } from '../features/codes/codesSlice';
import { deleteCode } from '../features/codes/codesSlice';
import { FaTrashAlt } from 'react-icons/fa';
import StyledInputField from './StyledComponents/StyledInputField';
import QRCodeStyling from 'qr-code-styling';
import { useEffect } from 'react';
import { useRef } from 'react';
import QrEditStyleForm from './QrEditStyleForm';
import QrDataForm from './QrDataForm';

const Modal = () => {
  const { code } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(code.status);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [styleOptions, setStyleOptions] = useState(code.stylingOptions);
  const qrCodeRef = useRef(null);

  useEffect(() => {
    // Update QR code properties here if needed
    if (qrCodeRef.current) {
      qrCodeRef.current.update(styleOptions);
    }
  }, [styleOptions]);

  useEffect(() => {
    if (!qrCodeRef.current) {
      qrCodeRef.current = new QRCodeStyling(styleOptions);
      qrCodeRef.current.append(document.getElementById('canvas'));
    }
  }, []);

  return (
    <>
      <div className='flex max-h-full bg-gray-700/30 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0'>
        <div className='flex max-h-full m-auto w-11/12 md:w-2/3'>
          <section className='max-h-full relative space-y-2  items-center m-auto flex-col bg-gray-50 shadow-md rounded-lg border border-gray-400 w-full flex justify-center text-gray-600 mb-3'>
            <span className='flex justify-center bg-gray-200 h-10 font-bold w-full items-center'>
              QR Info
            </span>
            <div className='flex flex-col md:flex-row items-center justify-center w-full h-full '>
              <div className='flex flex-col items-center justify-center w-full h-full'>
                <div id='canvas' className='flex max-w-72 max-h-72 '></div>

                <Link
                  className='mb-5 underline text-blue-500'
                  target='_blank'
                  rel='noopener noreferrer'
                  to={`/redirect?id=${code._id}&test=true`}
                >
                  Test Link
                </Link>
                <div className='flex items-center justify-center mb-4 w-full'>
                  <input
                    type='checkbox'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className='appearance-none w-9 focus:outline-none checked:bg-green-300 h-5 bg-red-300 rounded-full before:inline-block before:rounded-full before:bg-gray-500 before:h-4 before:w-4 checked:before:translate-x-full shadow-inner transition-all duration-300 before:ml-0.5'
                  />
                  {isChecked ? (
                    <span className='ml-2 text-sm'>This QR is Active</span>
                  ) : (
                    <span className='ml-2 text-sm'>This QR is Deactivated</span>
                  )}
                </div>
              </div>
              <div className='flex flex-col items-center justify-center w-full h-full p-6'>
                <QrDataForm
                  code={code}
                  styleOptions={styleOptions}
                  setStyleOptions={setStyleOptions}
                  isChecked={isChecked}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Modal;
