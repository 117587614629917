import React, { useState } from 'react';

const QrEditStyleForm = ({ styleOptions, setStyleOptions }) => {
  const handleConfigChange = (e) => {
    const { name, value, type } = e.target;
    setStyleOptions((prevOptions) => {
      // Deep clone to avoid direct state mutation
      const newOptions = JSON.parse(JSON.stringify(prevOptions));

      const keys = name.split('.');
      let current = newOptions;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] =
        type === 'checkbox' ? e.target.checked : value;

      return newOptions;
    });
  };
  function parsePath(path) {
    // Split the path by dots and handle square brackets for array indices
    return path.split('.').reduce((acc, part) => {
      if (part.includes('[')) {
        const [key, index] = part.split('[');
        acc.push(key, parseInt(index.replace(']', ''), 10));
      } else {
        acc.push(part);
      }
      return acc;
    }, []);
  }
  function getNestedValue(obj, path) {
    return path.reduce(
      (acc, val) => (acc && acc[val] != null ? acc[val] : null),
      obj
    );
  }

  function setNestedValue(obj, path, value) {
    if (path.length > 0) {
      let current = obj;
      for (let i = 0; i < path.length - 1; i++) {
        if (current[path[i]] === undefined) {
          current[path[i]] = {};
        }
        current = current[path[i]];
      }
      current[path[path.length - 1]] = value;
    }
  }

  function swapValues(obj, path1, path2) {
    // Deep clone the object
    const clonedObj = deepClone(obj);

    const parsedPath1 = parsePath(path1);
    const parsedPath2 = parsePath(path2);

    let temp = getNestedValue(clonedObj, parsedPath1);
    setNestedValue(
      clonedObj,
      parsedPath1,
      getNestedValue(clonedObj, parsedPath2)
    );
    setNestedValue(clonedObj, parsedPath2, temp);

    return clonedObj;
  }
  const handleSwapColors = (path1, path2) => {
    setStyleOptions((prevOptions) => {
      return swapValues(prevOptions, path1, path2);
    });
  };

  const handleColorCode = (e) => {
    const { name, value } = e.target;

    // Assuming name is in the format 'backgroundOptions.color.hex'
    const propertyPath = name.split('.');
    if (propertyPath.length === 3 && propertyPath[1] === 'color') {
      setStyleOptions((prevOptions) => ({
        ...prevOptions,
        backgroundOptions: {
          ...prevOptions.backgroundOptions,
          [propertyPath[1]]: value, // updates backgroundOptions.color
        },
      }));
    }
  };

  const handleGradientTypeChange = (e) => {
    const { value } = e.target;
    setStyleOptions((prevOptions) => ({
      ...prevOptions,
      dotsOptions: {
        ...prevOptions.dotsOptions,
        gradient: {
          ...prevOptions.dotsOptions.gradient,
          type: value,
        },
      },
    }));
  };

  const handleDotsGradientChange = (e) => {
    const { name, value } = e.target;

    // Extract the index of the color stop from the name attribute
    const index = parseInt(name.split('[')[1].split(']')[0], 10);

    setStyleOptions((prevOptions) => ({
      ...prevOptions,
      dotsOptions: {
        ...prevOptions.dotsOptions,
        gradient: {
          ...prevOptions.dotsOptions.gradient,
          colorStops: prevOptions.dotsOptions.gradient.colorStops.map(
            (stop, i) => {
              if (i === index) {
                return { ...stop, color: value };
              }
              return stop;
            }
          ),
        },
      },
    }));
  };
  const handleCornersSquareGradientChange = (e) => {
    const { name, value } = e.target;
    // Extract the index from the input name, which should be in the format 'cornersSquareOptions.gradient.colorStops[index].color'
    const index = parseInt(name.match(/\[(\d+)\]/)[1], 10);

    setStyleOptions((prevOptions) => ({
      ...prevOptions,
      cornersSquareOptions: {
        ...prevOptions.cornersSquareOptions,
        gradient: {
          ...prevOptions.cornersSquareOptions.gradient,
          colorStops: prevOptions.cornersSquareOptions.gradient.colorStops.map(
            (stop, i) => {
              return i === index ? { ...stop, color: value } : stop;
            }
          ),
        },
      },
    }));
  };
  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  const handleCornersDotOptionsChange = (e) => {
    const { name, value } = e.target;
    // Extract the index from the input name, which should be in the format 'cornersSquareOptions.gradient.colorStops[index].color'
    const index = parseInt(name.match(/\[(\d+)\]/)[1], 10);

    setStyleOptions((prevOptions) => ({
      ...prevOptions,
      cornersDotOptions: {
        ...prevOptions.cornersDotOptions,
        gradient: {
          ...prevOptions.cornersDotOptions.gradient,
          colorStops: prevOptions.cornersDotOptions.gradient.colorStops.map(
            (stop, i) => {
              return i === index ? { ...stop, color: value } : stop;
            }
          ),
        },
      },
    }));
  };

  // Other handlers like handleColorCode, handleGradientTypeChange, etc.

  return (
    <div
      id='editAppearance'
      className='flex flex-col items-center justify-center w-full'
    >
      <form
        id='codeAppearance'
        className='flex flex-col items-center justify-center w-full '
      >
        <table className='table-auto w-full'>
          <tr>
            <td>Bg Color:</td>
            <td>
              <input
                type='color'
                name='backgroundOptions.color'
                onChange={handleConfigChange}
                value={styleOptions.backgroundOptions.color}
              />
            </td>
            <td>
              {' '}
              <input
                type='text'
                name='backgroundOptions.color.hex'
                onChange={handleColorCode}
                value={styleOptions.backgroundOptions.color}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className=' mb-2 mr-5 text-sm font-medium text-gray-900 dark:text-white'>
                QR Style
              </div>
            </td>
            <td>
              <div className='flex flex-row justify-between'>
                <select
                  name='dotsOptions.type'
                  value={styleOptions.dotsOptions.type}
                  onChange={handleConfigChange}
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                >
                  <option value='rounded'>Rounded</option>
                  <option value='dots'>Dots</option>
                  <option value='classy'>Classy</option>
                  <option value='classy-rounded'>Classy Rounded</option>
                  <option value='square'>Square</option>
                  <option value='extra-rounded'>Extra Rounded</option>
                </select>
              </div>
            </td>
            <td>
              <label>
                <input
                  type='radio'
                  name='dotsOptions.gradient.type'
                  value='linear'
                  checked={styleOptions.dotsOptions.gradient.type === 'linear'}
                  onChange={handleGradientTypeChange}
                />
                Linear
              </label>
            </td>
            <td>
              <label>
                <input
                  type='radio'
                  name='dotsOptions.gradient.type'
                  value='radial'
                  checked={styleOptions.dotsOptions.gradient.type === 'radial'}
                  onChange={handleGradientTypeChange}
                />
                Radial
              </label>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>Color</td>
            <td>
              <input
                type='color'
                name='dotsOptions.gradient.colorStops[0].color'
                value={styleOptions.dotsOptions.gradient.colorStops[0].color}
                onChange={handleDotsGradientChange}
              />
            </td>
            <td>
              <button
                type='button'
                onClick={() =>
                  handleSwapColors(
                    'dotsOptions.gradient.colorStops[0].color',
                    'dotsOptions.gradient.colorStops[1].color'
                  )
                }
              >
                <svg
                  class='text-blue-600 w-10 h-10'
                  xmlns='http://www.w3.org/2000/svg'
                  stroke-width='2'
                  stroke='currentColor'
                  fill='none'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  {' '}
                  <path stroke='none' d='M0 0h24v24H0z' />{' '}
                  <polyline points='16 3 20 7 16 11' />{' '}
                  <line x1='10' y1='7' x2='20' y2='7' />{' '}
                  <polyline points='8 13 4 17 8 21' />{' '}
                  <line x1='4' y1='17' x2='13' y2='17' />
                </svg>
              </button>
            </td>
            <td>
              {' '}
              <input
                type='color'
                name='dotsOptions.gradient.colorStops[1].color'
                value={styleOptions.dotsOptions.gradient.colorStops[1].color}
                onChange={handleDotsGradientChange}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Corners Square Style</label>
            </td>
            <td>
              <select
                name='cornersSquareOptions.type'
                value={styleOptions.cornersSquareOptions.type}
                onChange={handleConfigChange}
              >
                <option value=''>None</option>
                <option value='dot'>Dot</option>
                <option value='square'>Square</option>
                <option value='extra-rounded'>Extra Rounded</option>
              </select>
            </td>
            <td>
              {' '}
              <label>
                <input
                  type='radio'
                  name='cornersSquareOptions.gradient.type'
                  value='linear'
                  checked={
                    styleOptions.cornersSquareOptions.gradient.type === 'linear'
                  }
                  onChange={handleConfigChange}
                />
                Linear
              </label>
            </td>
            <td>
              <label>
                <input
                  type='radio'
                  name='cornersSquareOptions.gradient.type'
                  value='radial'
                  checked={
                    styleOptions.cornersSquareOptions.gradient.type === 'radial'
                  }
                  onChange={handleConfigChange}
                />
                Radial
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <label>Corner Square Gradient</label>
            </td>
            <td>
              <input
                type='color'
                name='cornersSquareOptions.gradient.colorStops[0].color'
                value={
                  styleOptions.cornersSquareOptions.gradient.colorStops[0].color
                }
                onChange={handleCornersSquareGradientChange}
              />
            </td>
            <td>
              <button
                type='button'
                onClick={() =>
                  handleSwapColors(
                    'cornersSquareOptions.gradient.colorStops[0].color',
                    'cornersSquareOptions.gradient.colorStops[1].color'
                  )
                }
              >
                <svg
                  class='text-blue-600 w-10 h-10'
                  xmlns='http://www.w3.org/2000/svg'
                  stroke-width='2'
                  stroke='currentColor'
                  fill='none'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  {' '}
                  <path stroke='none' d='M0 0h24v24H0z' />{' '}
                  <polyline points='16 3 20 7 16 11' />{' '}
                  <line x1='10' y1='7' x2='20' y2='7' />{' '}
                  <polyline points='8 13 4 17 8 21' />{' '}
                  <line x1='4' y1='17' x2='13' y2='17' />
                </svg>
              </button>
            </td>
            <td>
              <input
                type='color'
                name='cornersDotOptionsHelper.gradient.colorStops[1].color'
                value={
                  styleOptions.cornersSquareOptions.gradient.colorStops[1].color
                }
                onChange={handleCornersSquareGradientChange}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Corners Dot Style</label>
            </td>
            <td></td>
            <td></td>
            <td>
              {' '}
              <select
                name='cornersDotOptions.type'
                value={styleOptions.cornersDotOptions.type}
                onChange={handleConfigChange}
              >
                <option value=''>None</option>
                <option value='dot'>Dot</option>
                <option value='square'>Square</option>
                {/* Add other options as necessary */}
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label>Corner Square Gradient Options</label>
            </td>
            <td>
              <input
                type='color'
                name='cornersDotOptions.gradient.colorStops[0].color'
                value={
                  styleOptions.cornersDotOptions.gradient.colorStops[0].color
                }
                onChange={handleCornersDotOptionsChange}
              />
            </td>
            <td>
              {' '}
              <button
                type='button'
                onClick={() =>
                  handleSwapColors(
                    'cornersDotOptions.gradient.colorStops[0].color',
                    'cornersDotOptions.gradient.colorStops[1].color'
                  )
                }
              >
                <svg
                  class='text-blue-600 w-10 h-10'
                  xmlns='http://www.w3.org/2000/svg'
                  stroke-width='2'
                  stroke='currentColor'
                  fill='none'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  {' '}
                  <path stroke='none' d='M0 0h24v24H0z' />{' '}
                  <polyline points='16 3 20 7 16 11' />{' '}
                  <line x1='10' y1='7' x2='20' y2='7' />{' '}
                  <polyline points='8 13 4 17 8 21' />{' '}
                  <line x1='4' y1='17' x2='13' y2='17' />
                </svg>
              </button>
            </td>
            <td>
              <input
                type='color'
                name='cornersDotOptions.gradient.colorStops[1].color'
                value={
                  styleOptions.cornersDotOptions.gradient.colorStops[1].color
                }
                onChange={handleCornersDotOptionsChange}
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <div></div>

        {/* Corners dot options */}
        <div></div>
      </form>
    </div>
  );
};

export default QrEditStyleForm;
