import React from "react";
import PlanCard from "../components/PlanCard";

function UpgradePlan() {
  return (
    <div
      className="container flex justify-center m-auto items-center h-full
    "
    >
      <div className="container justify-center items-center space-y-5 md:space-y-0 flex flex-col md:flex-row md:space-x-5 md:mt-20 ">
        <PlanCard title="Free" price="0 $">
          {" "}
          This is Freeee
        </PlanCard>

        <PlanCard title="Premium" price="5 $">
          {" "}
          This is Premium
        </PlanCard>

        <PlanCard title="Full" price="10 $">
          {" "}
          This is Full
        </PlanCard>
      </div>
    </div>
  );
}

export default UpgradePlan;
