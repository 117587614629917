import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserLinks } from '../features/auth/authSlice';
import StyledInputButton from '../components/StyledComponents/StyledInputButton';
import socialIconsList from '../utils/socialIconList';
import Spinner from '../components/Spinner';

function Landing() {
  const { username } = useParams();
  const { links, isLoading } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserLinks(username));
    console.log(links);
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }
  if (!links || links.length === 0) {
    return (
      <div className='flex flex-col items-center justify-center w-full h-full flex-grow '>
        <span className='flex justify-center mt-20'>
          User has no links published yet
        </span>
      </div>
    );
  } else {
    return (
      <div className='flex flex-col items-start justify-start w-full h-full flex-grow bg-mainText text-mainBackground'>
        <div className='mx-auto flex-col mt-10 max-w-lg space-y-10 w-full'>
          <div className='flex text-2xl font-bold justify-center capitalize'>
            {username}
          </div>
          <div className='mt-10 flex-col space-y-4 w-full'>
            <div className='flex flex-col w-full space-y-4 '>
              {links.map((link, index) => (
                <StyledInputButton
                  className='flex justify-center rounded-xl bg-mainBackground '
                  key={index}
                  onClick={() => {
                    window.location.replace(link.url);
                  }}
                >
                  <div className='flex flex-row w-full justify-center space-x-4 items-center'>
                    <span className='flex text-xl'>
                      {link.icon &&
                        socialIconsList.find((icon) => icon.name === link.icon)
                          .icon}
                    </span>
                    <span className='flex'>{link.name}</span>
                  </div>
                </StyledInputButton>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
