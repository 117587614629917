import { useState, useEffect } from "react";
import { FaSignInAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";
import StyledInputField from "../components/StyledComponents/StyledInputField";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isSuccess || user) {
      navigate("/dashboard");
    } else if (isError) {
      toast.error(message);
    }

    dispatch(reset);
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: email.toLowerCase(),
      password,
    };
    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section
        id="login"
        className="flex container mx-auto min-h-full items-center justify-center px-6 py-6"
      >
        {/* Flex Container */}
        <div className="flex container flex-col-reverse xl:lg:flex-row-reverse sm:flex-col-reverse justify-center items-center px-6 m-auto mt-10 mb-10 ">
          {/* Left Item */}
          <section className="flex items-center">
            {/* Right Item */}
            <div className="flex flex-col">
              <div className="flex marker:justify-center p-4 ">
                <form onSubmit={onSubmit}>
                  <p className="flex max-w-md text-gray-400 font-bold md:text-left justify-center mb-5">
                    Welcome back !
                  </p>
                  <div className="flex">
                    <StyledInputField
                      type="text"
                      id="email"
                      name="email"
                      label="Email"
                      value={email}
                      placeholder="Enter your email"
                      onChange={onChange}
                    />
                  </div>
                  <div className="flex">
                    <StyledInputField
                      type="password"
                      id="password"
                      name="password"
                      label="Password"
                      value={password}
                      placeholder="Enter your Password"
                      onChange={onChange}
                    />
                  </div>
                  <button
                    type="Submit"
                    className="bg-orange-500 flex min-w-full items-center mt-5 text-center justify-center hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-blue-500 rounded"
                    id="submit"
                  >
                    <FaSignInAlt className="mr-5" /> Login
                  </button>
                  <div className="flex flex-row justify-between">
                    <a
                      href="/login"
                      className="flex max-w-md text-gray-400 font-thin md:text-left justify-center mb-5 mt-5 hover:text-mainText"
                    >
                      Forgot your password ?
                    </a>
                    <a
                      href="/register"
                      className="flex max-w-md text-gray-400 font-thin md:text-left justify-center mb-5 mt-5 hover:text-mainText"
                    >
                      Create an account
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section className="flex">
            <h1 className="hidden xl:lg:flex max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
              Sign in and start managing your QRs...
            </h1>
          </section>
        </div>
      </section>
    </>
  );
}

export default Login;
