function Spinner({ text }) {
  return (
    <div className='flex items-center flex-col justify-center min-h-screen p-5 bg-mainBackground min-w-screen'>
      <div className='font-bold mb-10'>{text ? text : 'Loading... '}</div>
      <div className='flex space-x-2 animate-pulse'>
        <div className='w-3 h-3 bg-gray-500 rounded-full'></div>
        <div className='w-3 h-3 bg-gray-500 rounded-full'></div>
        <div className='w-3 h-3 bg-gray-500 rounded-full'></div>
      </div>
    </div>
  );
}

export default Spinner;
