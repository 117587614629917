import React from "react";
import PlanCard from "../components/PlanCard";

function About() {
  return (
    <div className="relative">
      <div className="sticky top-0 h-screen flex flex-col items-center justify-center bg-orange-500">
        <h2 className="text-4xl">All your QR Codes in the same Place</h2>
        <p>Keep Scrolling</p>
      </div>
      <div className="sticky top-0 h-screen flex flex-col items-center justify-center bg-neutral-800 text-mainText">
        <h2 className="text-4xl">
          Choose where do you want this QR to take you
        </h2>
        <p>Scroll Down</p>
      </div>
      <div className="sticky top-0 h-screen flex flex-col items-center justify-center bg-mainText text-mainBackground">
        <h2 className="text-4xl"> More Information here</h2>
        <p>Scroll Down</p>
      </div>
      <div className="sticky top-0 h-screen flex flex-col items-center justify-center bg-neutral-800 text-mainText">
        <h2 className="text-4xl">Last title</h2>
        <p>No more scrolling</p>
      </div>
    </div>
  );
}

export default About;
